import { Link, useLocation } from "react-router-dom";

const Header = () => {
    const location = useLocation();
	const isContactPage =
	location.pathname === "/contact" ||
	location.pathname === "/mentions-legales" ||
	location.pathname === "/politique-de-confidentialite";
	
	function openMenu(e) {
		e.preventDefault();
		var x = document.getElementById("menu-container");
		if (x.style.display === "block") {
		  x.style.display = "initial";
		  x.style.transform = "translateY(-200%)"
		} else {
		  x.style.display = "block";
		  x.style.transform = "translateY(0)"
		}
	  }
	
	  function closeMenu() {
		var x = document.getElementById("menu-container");
		x.style.display = "initial";
		x.style.transform = "translateY(-200%)"
	
		window.scroll({
		  top: 0,
		  behavior: 'smooth'
		});
	  }
	

	  function scrollTop(event) {
        event.preventDefault(); // Prevent the default navigation behavior
        const destination = event.currentTarget.getAttribute("href"); // Get the destination URL from the 'to' attribute
    
        window.scroll({
            top: 0
          });

		  closeMenu()

          
          // Add a slight delay before navigating to ensure smooth scrolling completes
          setTimeout(() => {
			
            window.location.href = destination; // Navigate to the target URL after scrolling
			
          }, 500); // Adjust the delay time as needed
      }


    function scrollDown() {
        window.scroll({
          top: window.innerHeight,
          behavior: 'smooth'
        });
      }

    return (
		<>
        <header>
            <h1 className={isContactPage ? "contact-title" : ""}>
			<Link to="/" onClick={scrollTop}>
			<svg width="744" height="742" viewBox="0 0 744 742" fill="none">
				<path fillRule="evenodd" clipRule="evenodd" d="M740.268 512.366C730.229 512.057 720.427 511.756 707.149 480.497C707.149 480.497 703.201 469.085 595.719 211H588.261L512.366 411.148C503.014 433.776 495.178 451.315 488.522 464.919V424.208C488.522 424.208 450.339 403 374.418 403C336.201 403 312.347 412.966 298.55 422.039C302.086 407.71 304 391.549 304 373.338C304 242.653 206.079 215 117.505 215H0V222.582C19.5842 222.582 33.3821 248.898 33.3821 276.551V467.895C33.3821 498.224 19.1391 521.418 0 521.418V529H117.505C171.208 529 228.347 519.654 265.014 483.267C265.679 513.583 290.156 541.996 332.239 568.244L400.613 611.101C454.779 650.424 440.128 714.489 392.621 714.489C316.131 714.489 289.966 641.644 279.508 612.527C279.161 611.56 278.831 610.642 278.517 609.776H270.969V694.607C270.969 694.607 306.488 722 392.621 722C464.103 722 484.97 685.77 484.97 685.77C518.269 637.611 493.85 589.893 435.244 551.454L366.87 506.388C309.152 468.832 326.467 410.511 374.418 410.511C436.124 410.511 464.665 459.184 476.628 487.319C462.298 511.586 455.177 512.026 448.358 512.448C447.905 512.476 447.453 512.504 447 512.538V520H554.92V512.538C485.606 516.05 512.805 433.533 512.805 433.533H616.777L629.938 465.574C629.938 465.574 652.75 512.538 618.532 512.538V520H744V512.538C742.748 512.442 741.506 512.404 740.268 512.366ZM614.145 426.071H514.121L561.939 297.906L614.145 426.071ZM117.505 521.418C161.124 521.418 226.108 512.051 226.108 373.338C226.108 234.179 161.124 222.582 117.505 222.582H97.9209V521.418H117.505Z"/>
			</svg>	
			</Link>
            </h1>

			<ul  className={isContactPage ? "noMobile inline-menu inline-menu-contact" : "noMobile inline-menu"}>
				<li>
					<Link to="/" onClick={scrollTop}>
						Accueil
					</Link>
				</li>
		<li>
		<Link to="/nous-connaitre" onClick={scrollTop}>
		<span>Nous Connaître</span></Link>
		</li>
		<li>
		<Link to="/nos-services" onClick={scrollTop}>
		<span>Nos services</span>
		</Link>
		</li>
		<li>
		<Link to="/rse" onClick={scrollTop}>
		<span>RSE</span>
		</Link>
		</li>
		<li>
		<Link  to="/contact" onClick={scrollTop}>
			<span>Devis/ Contact</span>
		</Link>
		</li>
	</ul>

			<a href="https://www.linkedin.com/company/d-s-a-propret%C3%A9/" target="_blank" rel="nofollow" className="linkedIn">
			<svg width="65" height="65" viewBox="0 0 65 65" fill="none" className={isContactPage ? "linkedInContact" : ""}>
			<g clip-path="url(#clip0_437_6803)">
			<path d="M55.205 55.2044H45.605V40.1704C45.605 36.5854 45.541 31.9704 40.612 31.9704C35.612 31.9704 34.847 35.8764 34.847 39.9094V55.2034H25.2469V24.2874H34.463V28.5124H34.592C35.5143 26.9355 36.847 25.6382 38.4483 24.7587C40.0496 23.8793 41.8594 23.4506 43.685 23.5184C53.415 23.5184 55.209 29.9184 55.209 38.2444L55.205 55.2044ZM14.415 20.0614C13.3131 20.0616 12.236 19.7351 11.3197 19.1231C10.4034 18.5111 9.68929 17.6412 9.26746 16.6233C8.84562 15.6054 8.73506 14.4853 8.94983 13.4045C9.16459 12.3238 9.69502 11.3311 10.474 10.5518C11.253 9.77257 12.2455 9.24181 13.3262 9.02665C14.4068 8.8115 15.5269 8.92163 16.545 9.3431C17.563 9.76457 18.4332 10.4785 19.0455 11.3945C19.6578 12.3105 19.9848 13.3876 19.985 14.4894C19.9851 15.221 19.8411 15.9455 19.5613 16.6214C19.2814 17.2974 18.8712 17.9116 18.354 18.429C17.8368 18.9464 17.2227 19.3569 16.5468 19.637C15.871 19.917 15.1466 20.0613 14.415 20.0614ZM19.215 55.2044H9.60498V24.2874H19.215V55.2044ZM59.991 0.00441416H4.78101C3.5279 -0.00972719 2.32037 0.474209 1.42389 1.34988C0.527404 2.22555 0.0152935 3.42132 0 4.67441V60.1134C0.0147701 61.3671 0.52658 62.5637 1.42303 63.4403C2.31949 64.3169 3.52727 64.8018 4.78101 64.7884H59.991C61.2472 64.8042 62.4583 64.3207 63.3583 63.4441C64.2583 62.5676 64.7736 61.3696 64.791 60.1134V4.67041C64.773 3.41483 64.2574 2.21771 63.3574 1.34206C62.4574 0.466407 61.2466 -0.0161471 59.991 0.000412547" fill="#273A55"/>
			</g>
			<defs>

			</defs>
			</svg>
			</a>




        </header>

		

		<nav className="menu">
				<button onClick={openMenu} className="action action--menu"><svg className="icon icon--menu"><use xlinkHref="#icon-menu"></use></svg></button>
				<button className="action action--close"><svg className="icon icon--close"><use xlinkHref="#icon-close"></use></svg></button>
		</nav>

<div id="menu-container">

<h1 className={isContactPage ? "home-title" : ""}>
			<Link to="/" onClick={scrollTop}><img src="img/logo.jpg" alt="DSA"/></Link>
            </h1>

<a href="https://www.linkedin.com/company/d-s-a-propret%C3%A9/" target="_blank" rel="nofollow" className="linkedIn">
	<img src="img/linkedin.svg" alt="LinkedIn"/>
</a>

<ul>
		<li>
		<Link to="/nous-connaitre" onClick={scrollTop}>
		<div className="menu-image image-blend dark">
              <img src="img/nous-connaitre.jpg"/>
            </div>
		<span>Nous Connaître</span></Link>

		</li>
		<li>
		<Link to="/nos-services" onClick={scrollTop}>
		<div className="menu-image image-blend primary">
              <img src="img/nos-services.jpg"/>
            </div>
		<span>Nos services</span>
		</Link>
		</li>
		<li>
		<Link to="/rse" onClick={scrollTop}>
		<div className="menu-image image-blend light">
              <img src="img/rse.jpg"/>
            </div>
		<span>RSE</span>
		</Link>
		</li>
		<li>
		<Link  to="/contact" onClick={scrollTop}>
			<div className="menu-image image-blend ocre">
				<img src="img/contact.jpg"/>
			</div>
			<span>Contact</span>
		</Link>
		</li>
	</ul>
</div>
</>
    );
};

export default Header;
