
import React, { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import gsap from "gsap";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/effect-fade';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import Fade from 'react-reveal/Fade';

const Home = () => {


  const itemsRef = useRef([]);

  function scrollTop(event) {
    event.preventDefault(); // Prevent the default navigation behavior
    const destination = event.currentTarget.getAttribute("href"); // Get the destination URL from the 'to' attribute

    window.scroll({
        top: 0,
      });
      
      // Add a slight delay before navigating to ensure smooth scrolling completes
      setTimeout(() => {
        window.location.href = destination; // Navigate to the target URL after scrolling
      }, 500); // Adjust the delay time as needed
  }

  function scrollDown() {
    window.scroll({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  }

  const [isImageBlendActiveNousConnaitre, setImageBlendActiveNousConnaitre] = useState(false);

  const handleHoverNousConnaitre = () => {
    setImageBlendActiveNousConnaitre(!isImageBlendActiveNousConnaitre);
  };

  const [isImageBlendActiveNosServices, setImageBlendActiveNosServices] = useState(false);

  const handleHoverNosServices = () => {
    setImageBlendActiveNosServices(!isImageBlendActiveNosServices);
  };

  const [isImageBlendActiveRse, setImageBlendActiveRse] = useState(false);

  const handleHoverRse = () => {
    setImageBlendActiveRse(!isImageBlendActiveRse);
  };

  const [isImageBlendActiveContact, setImageBlendActiveContact] = useState(false);

  const handleHoverContact = () => {
    setImageBlendActiveContact(!isImageBlendActiveContact);
  };

  useEffect(() => {
    const observerOptions = {
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('Intersecting:', entry.target);
            gsap.to(entry.target.querySelector(".img-overlay"), {
              width: 0,
              duration: 0.75,
            });
          }
        });
      },
      observerOptions
    );

    itemsRef.current.forEach((item) => {
      observer.observe(item);
      console.log('Observer:', item);
    });

    return () => {
      observer.disconnect();
      console.log('Observer disconnected');
    };
  }, []);

  return (
    <>
     <Swiper
        spaceBetween={0}
        effect={'fade'}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={false}
        navigation={false}
        modules={[Autoplay, EffectFade]}
      >
        <Fade top>
          <div className='title-container'>
           <h1>D.S.A
           <span>Groupe Multiservices </span>
           </h1>
           </div>
        </Fade>
        
        <button className="plus" onClick={scrollDown}>
          En savoir plus
          <span className='center'><img src="/img/plus.svg"/></span>  
        </button>
        <SwiperSlide className="image-blend primary" style={{ backgroundImage: "url('img/home-1.jpg')" }}></SwiperSlide>
        <SwiperSlide style={{ backgroundImage: "url('img/home-2.jpg')" }}></SwiperSlide>
      </Swiper>

      <div className="navigation-home">
      <Fade top>
        <div className="block-big">
          <div className="content-home">
            <div className={`image-blend ${isImageBlendActiveNousConnaitre ? "active" : "dark"}`}>
               <img src="img/nous-connaitre-home.jpg" />
             </div>

             <Link to="/nous-connaitre" onClick={scrollTop}
                           onMouseEnter={handleHoverNousConnaitre}
                           onMouseLeave={handleHoverNousConnaitre}
             >
              <span>

                <h3>Nous Connaître</h3>
                <span class="action action--arrow-white"><svg class="icon icon--menu"><use xlinkHref="#icon-arrow-white"></use></svg></span>
              </span>
              </Link>

          </div>
        </div>

      {
        // Services
      }

        <div className="block-big">
          <div className="content-home">
            <div className={`image-blend ${isImageBlendActiveNosServices ? "active" : "primary"}`}>
               <img src="img/services-home.jpg" />
             </div>

             <Link to="/nos-services" onClick={scrollTop}
                onMouseEnter={handleHoverNosServices}
                onMouseLeave={handleHoverNosServices}
             >
              <span>
                <h3>Nos Services</h3>
                <span class="action action--arrow-white"><svg class="icon icon--menu"><use xlinkHref="#icon-arrow-white"></use></svg></span>
              </span>
              </Link>

          </div>
        </div>

      {
        // RSE
      }

        <div className="block-big">
          <div className="content-home">
            <div className={`image-blend ${isImageBlendActiveRse ? "active" : "light"}`}>
               <img src="img/rse-home.jpg" />
             </div>

             <Link to="/rse" onClick={scrollTop}
                           onMouseEnter={handleHoverRse}
                           onMouseLeave={handleHoverRse}
             >
              <span>

                <h3>Stratégie RSE</h3>
                <span class="action action--arrow-white"><svg class="icon icon--menu"><use xlinkHref="#icon-arrow-white"></use></svg></span>
              </span>
              </Link>

          </div>
        </div>
      </Fade>
      </div>

      <Fade top>
      <h1>D.S.A</h1>
      <h2>Un Groupe Multiservices</h2>
      </Fade>

      <Fade top>
<div className="p-content">
    <p>
      Crée par Hugo DUPERRET, D.S.A est une société de nettoyage et d’entretien multiservices, plaçant le client au cœur de sa politique d’entreprise. Souhaitant répondre à tous types de besoins, notre société est spécialisée dans chaque domaines du « nettoyage » et est présente dans toute la région Rhône-Alpes pour vous satisfaire.
    </p>
    <p>
      <i><b>
        Parce que le sens du service et la satisfaction client sont les moteurs de notre société, D.S.A souhaite vous accompagner au quotidien dans l’amélioration et l’optimisation de votre image. 
      </b></i>
    </p>
</div>
</Fade>

<section>
  <div className='c'>
    <div className="txt">
    <Fade top>
      <h3>Nous connaitre </h3>
      </Fade>
      <Fade top>
      <p>Fort de + de 15 ans dans les métiers de la propreté, nos équipes 100 % salariés travaillent autour des valeurs suivantes : <br/>
      PASSION-REACTIVITE-IDENTIFICATION-EXCELLENCE-DIVERSITE-INNOVATION</p>
      <Link to="/nous-connaitre" onClick={scrollTop}>
      <span className="plusLink">
                En savoir plus
                <span><img src="/img/plus-dark.svg"/></span> 
              </span>
      </Link>
      </Fade>
      </div>
  </div>
  <div className='c' ref={(el) => itemsRef.current.push(el)}>
      <img src="img/dsa1.jpg"/>
                <div className="img-overlay"></div>

    </div>
</section>

<section className="revert">
<div className='c' ref={(el) => itemsRef.current.push(el)}>
      <img src="img/f3.jpg"/>
                <div className="img-overlay"></div>

    </div>
  <div className='c'>
    <div className="txt">
    <Fade top>
      <h3>Nos Services</h3>
      </Fade>
  <Fade top>
          <p>
        Chez DSA, nous comprenons que chaque espace et chaque client à sa propre exigence unique en matière de nettoyage. C'est pourquoi nous proposons des services personnalisés, adaptés à vos besoins spécifiques.
        </p>
        <p>
        Entretien récurrent, entretien spécifique, nettoyage de fin de chantier ou encore entretien de vos véhicules, canapés ou tapis : nos équipes sont formées pour tous besoins liés aux métiers du nettoyage.
        </p>
        <Link to="/nos-services" onClick={scrollTop}>
      <span className="plusLink">
                En savoir plus
                <span><img src="/img/plus-dark.svg"/></span> 
              </span>
      </Link>
      </Fade>
      </div>

  </div>

</section>

<section>
  <div className='c'>
  <div className="txt">
  <Fade top>
  <h3>STRATÉGIE RSE</h3>
  </Fade>
  <Fade top>
    <p>
     Société jeune, mais dynamique : D.S.A souhaite s’engager auprès de ses équipes pour une meilleure responsabilité sociale en entreprise.
    </p>
    <p>
      Notre entreprise se veut novatrice et source de proposition concernant les enjeux suivants : <br/>
      ENJEU SOCIAL - ENJEU ENVIRONNEMENTAL – ENJEU SOCIETAL - ENJEU ECONOMIQUE.
    </p>
    <Link to="/rse" onClick={scrollTop}>
    <span className="plusLink">
                En savoir plus
                <span><img src="/img/plus-dark.svg"/></span> 
              </span>
      </Link>
      </Fade>
      </div>
  </div>
  <div className='c' ref={(el) => itemsRef.current.push(el)}>
      <img src="img/f16.jpg"/>
                <div className="img-overlay"></div>

    </div>
</section>

<section className="revert">
<div className='c' ref={(el) => itemsRef.current.push(el)}>
      <img src="img/f17.jpg"/>
                <div className="img-overlay"></div>

    </div>
  <div className='c'>
  <div className="txt">
  <Fade top>
    <h3>Devis/ Contact</h3>
    </Fade>
    <Fade top>
    <p>
      Nos équipes disponibles 7j/7j et 24h/24h s’engagent à répondre à vos besoins, dans les plus brefs délais.
    </p>
    <p>
      Quelques informations à nous faire parvenir, et nous vous contacterons immédiatement.
    </p>
    <Link to="/contact" onClick={scrollTop}>
              <span className="plusLink">
                En savoir plus
                <span><img src="/img/plus-dark.svg"/></span> 
              </span>
      </Link>
      </Fade>
      </div>
  </div>

</section>

    </>
  );
};

export default Home;
