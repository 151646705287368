import { Link} from "react-router-dom";
import Fade from 'react-reveal/Fade';

const RSE = () => {
    function scrollTop(event) {
        event.preventDefault(); // Prevent the default navigation behavior
        const destination = event.currentTarget.getAttribute("href"); // Get the destination URL from the 'to' attribute
    
        window.scroll({
            top: 0
          });
          
          // Add a slight delay before navigating to ensure smooth scrolling completes
          setTimeout(() => {
            window.location.href = destination; // Navigate to the target URL after scrolling
          }, 500); // Adjust the delay time as needed
      }
    function scrollDown() {
        window.scroll({
          top: window.innerHeight,
          behavior: 'smooth'
        });
      }
    return (
        <>
        <div className="hero"  style={{ backgroundImage: "url('img/rse-hero.jpg')" }}>
          <Fade top>
                <div className="title-container">
                <h1>Stratégie RSE</h1>
                </div>
          </Fade>
            
            <button className="plus" onClick={scrollDown}>
            En savoir plus
            <span className='center'><img src="/img/plus.svg"/></span>  
            </button>
        </div>
            <Fade top>
             <h2>Nous faisons la différence</h2>
            </Fade>
            <Fade top>
                <div className="p-content">
                <p>
                    Bienvenue dans notre entreprise engagée ! Chez DSA, nous sommes fiers de mettre en avant notre engagement dans quatre domaines clés : social, environnemental, économique et sociétal.
                </p>
                <p>  
                    Chacun de ces domaines reflète notre vision globale et notre volonté d'avoir un impact positif à tous les niveaux.
                </p>
                </div>
            </Fade>
            <div className="block-content">
                <div className="i dark">
                    <div className="c txt">
                        <h3>ENJEU SOCIAL</h3>
                        <p>
                        Nous nous engageons à créer un environnement de travail serein et épanouissant pour nos collaborateurs.<br/>
                        Cela passe par l'élimination des dangers et la réduction des risques en matière de santé et de sécurité au travail (SST).
                        </p>
                        <p>    
                        Nous accompagnons nos employés en proposant des horaires de travail en journée afin de réduire la précarité.<br/>
                        De plus, nous mettons en place des moment d'échanges et d'écoutes en accordant une grande importance à la consultation et à la participation de nos salariés et de leurs représentants afin d'améliorer leurs conditions de travail.
                        </p>
                    </div>
                </div>
                <div className="i">
                <div className="c big ocre">
                      <img src="img/f15.jpg"/>
                    </div>
                </div>
                </div>

                <div className="block-content revert">
                <div className="i">
                    <div className="c big light">
                      <img src="img/ecolabel.svg"/>
                    </div>
                </div>
                <div className="i primary">
                    <div className="c txt">
                        <h3>ENJEU ENVIRONNEMENTAL</h3>
                        <p>
                        La protection de l'environnement est une priorité pour nous. Nous cherchons à réduire l'impact de nos activités sur l'environnement en mettant en place des mesures concrètes.
                        </p>
                        <p>    
                        Cela inclut la diminution de notre empreinte carbone et l'intégration de critères environnementaux dans nos politiques d'achat. Nos produits d'entretien sont certifiés Eco-Label et nous sensibilisons nos équipes à l'utilisation de ces derniers et investissons dans le développement des compétences de nos collaborateurs en matière d'environnement.
                        </p>
                        </div>
                </div>
                </div>

                <div className="block-content">
                <div className="i dark">
                    <div className="c txt">
                        <h3>ENJEU ÉCONOMIQUE</h3>
                        <p>
                        Nous visons un développement économique et qualitatif pérenne. Pour y parvenir, nous partageons notre expérience et sommes force de proposition dans notre domaine d'activité.                        </p>
                        <p>    
                        Nous mettons tout en œuvre pour fidéliser nos clients en offrant des services de qualité qui répondent à leurs besoins.<br/>
                        De plus, nous nous engageons à respecter les exigences légales et autres normes en vigueur pour garantir une activité conforme et responsable.
                        </p>
                    </div>
                </div>
                <div className="i">
                <div className="c big light">
                      <img src="img/eco.svg"/>
                    </div>
                </div>
                </div>

                <div className="block-content revert">
                <div className="i">
                    <div className="c big light">
                      <img src="img/f10.jpg"/>
                    </div>
                </div>
                <div className="i ocre bold">
                    <div className="c txt">
                        <h3>ENJEU SOCIÉTAL</h3>
                        <p>
                        Nous sommes fiers de promouvoir notre charte éthique et de mettre en pratique nos valeurs sociétales. Nous mettons en avant les jeunes talents en les privilégiant et en les valorisant au sein de notre entreprise. Nous favorisons la diversité et l'inclusion dans nos équipes.                        </p>
                        <p>    
                        Nos projets à court et moyen terme, sont de pouvoir contribuer au développement de structures associatives qui visent à améliorer la société dans son ensemble et qui touchent à nos valeurs écologiques, environnementales et sociétales.                      </p>
                    </div>
                </div>
                </div>


            <div className="cta">
                <div className="i noMobile">
                    <div className="c">
                        <img src="img/shapes/f15.svg"/>
                        <img src="img/shapes/f16.svg"/>
                    </div>
                </div>
                <div className="i txt">
                    <div className="c txt">
                    <h3>Demandez un devis</h3>
                      <Link to="/contact" onClick={scrollTop}>
                      <span className="plusLink">
                            En savoir plus
                            <span><img src="/img/plus-dark.svg"/></span> 
                        </span>
                      </Link>
                    </div>
                </div>
            </div>

        </>
  );
};

export default RSE;