import Fade from 'react-reveal/Fade';

const Politique = () => {
    function scrollTop() {
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
    }

    return (
        <>
        <Fade top>
            <h2>Politique de confidentialité</h2>
			</Fade>
            <div className="p-content">
            <p>
                La confidentialité et la sécurité de vos informations sont importantes pour nous.
                </p>
            
            <p>
                Cette politique de confidentialité explique quelles informations nous collectons lorsque vous visitez notre site Web d'entreprise de ménage, comment nous les utilisons, et comment nous les protégeons. Nous nous engageons à respecter la confidentialité de vos données et à les traiter conformément à la législation en vigueur sur la protection des données.
             </p>

            <h4>Collecte d'Informations</h4>
            <p>Lorsque vous visitez notre site Web, nous pouvons collecter des informations personnelles telles que votre nom, adresse e-mail, numéro de téléphone, ou toute autre information nécessaire pour fournir nos services de ménage.<br/>
            Nous ne collectons ces informations que si vous les fournissez volontairement via nos formulaires de contact ou de demande de devis.
            </p>

        <h4>Utilisation des Informations</h4>
        <p>Les informations que nous collectons peuvent être utilisées pour répondre à vos demandes. <br/>
        Nous ne vendons, ne louons, ni ne partageons vos informations personnelles avec des tiers, sauf dans les cas où cela est nécessaire pour vous fournir nos services.
        </p>

        <h4>Sécurité des Données</h4>
        <p>Nous prenons des mesures appropriées pour protéger vos informations personnelles contre tout accès non autorisé, altération, divulgation, ou destruction. <br/>
        Seules les personnes autorisées de notre entreprise ont accès à vos informations, et elles sont tenues de les traiter de manière confidentielle.
        </p>

        <h4>Cookies et Technologies Similaires</h4>
        <p>Notre site Web peut utiliser des cookies et d'autres technologies similaires pour améliorer votre expérience en ligne et collecter des informations sur la manière dont vous utilisez notre site. <br/>
            Vous avez la possibilité de désactiver les cookies dans les paramètres de votre navigateur si vous le souhaitez.
        </p>

<h4>Modifications de la Politique de Confidentialité</h4>
<p>Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. <br/>
Toute modification sera publiée sur cette page, et la date de révision en bas de la page sera mise à jour en conséquence.
</p>

<h4>Consentement</h4>
<p>En utilisant notre site Web, vous consentez à la collecte et à l'utilisation de vos informations personnelles conformément à cette politique de confidentialité.
</p>

<p>Si vous avez des questions concernant notre politique de confidentialité ou la manière dont nous traitons vos données, veuillez nous contacter via les coordonnées fournies sur notre site Web.
</p>

<p>Date de la dernière mise à jour : 25/7/2023</p>
</div>
            
        </>

    )

}

export default Politique;