import React from 'react';
import Fade from 'react-reveal/Fade';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as FormData from 'form-data';
import Mailgun from 'mailgun.js';
const mailgun = new Mailgun(FormData);

const DOMAIN = "mg.dsa-group.fr";

let client = null; // Declare 'client' outside the try-catch block and set it to null

try {
  // Attempt to create the Mailgun client
  client = mailgun.client({
    username: 'api',
    key: '0528524bc591520711340f1367756c09-4e034d9e-3f2ce370',
    url: "https://api.eu.mailgun.net"
  });
} catch (error) {
  console.error('Error occurred while creating the Mailgun client:', error);
}

const Contact = () => {

  function handleSubmit(event) {
    event.preventDefault();

    // Get the form element by its id
    const form = document.getElementById('contactForm');

    // Get all the required input fields within the form
    const requiredFields = form.querySelectorAll('input[required], textarea[required]');

    // Check if the form is valid (i.e., all required fields are filled)
    if (!form.checkValidity()) {
      // If the form is not valid, add a class to the missing required fields and scroll to the top of the form
      requiredFields.forEach((field) => {
        if (!field.value.trim()) {
          field.classList.add('missing-field');
        } else {
          field.classList.remove('missing-field');
        }
      });

      // Scroll to the top of the form
      form.scrollIntoView({ behavior: 'smooth' });

      // Display an error message or take appropriate action (optional)
      toast.error('Merci de remplir tout les champs requis.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      return; // Exit the function, preventing form submission
    }

    // If the form is valid, continue with form submission logic
    const formData = new FormData(form);

    const messageData = {
      from: 'Formulaire DSA <form@dsa-group.fr>',
      to: 'contact@dsa-group.fr',
      subject: 'Prise de contact',
      text: `
        Nom: ${formData.get('firstName')} ${formData.get('lastName')}
        Société: ${formData.get('societe')}
        Email: ${formData.get('email')}
        Téléphone: ${formData.get('phone')}
        Message: ${formData.get('message')}
      `
    };

    client.messages.create(DOMAIN, messageData)
      .then((res) => {
        toast.success('Votre message a été envoyé!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        console.log(res);
		        // Reset the form after successful submission
				form.reset();
      })
      .catch((error) => {
        toast.error('Une erreur s\'est produite.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        console.error(error);
      });
  }

    return (
        <>
		<Fade top>
            <h1>Contact</h1>
			</Fade>
			<Fade top>
            <h2>Demandez un devis dès maintenant</h2>
			</Fade>
			<Fade top>
            <div className="p-content">
              <p>Confiez-nous votre nettoyage et concentrez-vous sur l'essentiel.</p>
            </div>
			</Fade>
            
            <div className="form-block">
			<form id="contactForm" className="form">
                <span class="input input--yoshiko">
					<input class="input__field input__field--yoshiko" type="text" id="firstName" name="firstName" placeholder='Prénom' required />

				</span>

                <span class="input input--yoshiko">
					<input class="input__field input__field--yoshiko" type="text" id="lastName" name="lastName" placeholder="Nom" required />
				</span>

                <span class="input input--yoshiko">
					<input class="input__field input__field--yoshiko" type="text" id="societe" name="societe" placeholder="Société" required />
				</span>

                <span class="input input--yoshiko">
					<input class="input__field input__field--yoshiko" type="email" id="email" name="email" placeholder="Email" required />
				</span>

                <span class="input input--yoshiko">
					<input class="input__field input__field--yoshiko" type="tel" id="phone" placeholder="Téléphone" name="phone" />
				</span>
                
                
                <label for="message">Votre demande</label>
                <textarea id="message" name="message" required></textarea>
                
                <button id="submit" onClick={handleSubmit}>Envoyer</button>
                </form>
            </div>

            <div className="img-container">
               <img src="img/contact-bottom.jpg"/>
             </div>

			 <ToastContainer />
        </>
  );
};

export default Contact;