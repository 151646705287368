import { Link} from "react-router-dom";
import Fade from 'react-reveal/Fade';

const NosServices = () => {
    // function scrollTop() {
    //     window.scroll({
    //       top: 0,
    //       behavior: 'smooth'
    //     });
    // }

    function scrollTop(event) {
        event.preventDefault(); // Prevent the default navigation behavior
        const destination = event.currentTarget.getAttribute("href"); // Get the destination URL from the 'to' attribute
    
        window.scroll({
            top: 0
          });
          
          // Add a slight delay before navigating to ensure smooth scrolling completes
          setTimeout(() => {
            window.location.href = destination; // Navigate to the target URL after scrolling
          }, 500); // Adjust the delay time as needed
      }

    function scrollDown() {
        window.scroll({
          top: window.innerHeight,
          behavior: 'smooth'
        });
      }

    return (
        <>

        <div className="hero"  style={{ backgroundImage: "url('img/nos-services-hero.jpg')" }}>
            
        <Fade top>
                <div className="title-container">
                     <h1>Nos Services</h1>
                </div>
            </Fade>
                <button className="plus" onClick={scrollDown}>
                En savoir plus
          <span className='center'><img src="/img/plus.svg"/></span>  
        </button>
        </div>

        <Fade top>
            <h2>NOTRE EXPERTISE EN NETTOYAGE</h2>
            </Fade>
            <Fade top>
            <div className="p-content">
                <p>
                Répondez à vos besoins spécifiques en matière de propreté et d'hygiène.
                </p>
                <p>
                Chez DSA, nous comprenons que chaque espace et chaque client ont des exigences uniques en matière de nettoyage. C'est pourquoi nous proposons des services personnalisés, adaptés à vos besoins spécifiques.
                </p>
                <p>
                Que vous soyez une entreprise, un particulier ou une organisation, nous sommes là pour vous fournir un service de qualité supérieure.
                </p>
                <p>
                Notre objectif est de vous offrir un environnement propre, sain et accueillant, en utilisant des techniques et des produits respectueux de l'environnement.
                </p>
            </div>
            </Fade>

            <div className="block-content">
                <div className="i ocre bold">
                    <div className="c txt">
                    <h3>ENTRETIEN E-R-P </h3>
                <p>
                Spécialisées dans l'entretien d’établissement recevant du public, nos équipes se chargent de la mise en propreté, une à plusieurs fois par jour, de restaurants, d'écoles, de cinémas, de lieux culturels et festifs, de banques et de tout autre espace accueillant régulièrement du public.
                </p>
                <p>
                Nos salariés identifiés et identifiables sont à votre disposition pour répondre à tous vos besoins avec réactivité et qualité.                </p>
                    </div>
                </div>
                <div className="i">
                <div className="c">
                        <img src="img/erp1.jpg"/>
                        <img src="img/erp2.jpg"/>
                        <img src="img/erp3.jpg"/>
                        <img src="img/erp4.png"/>
                    </div>
                </div>
                </div>

            <div className="block-content revert">
                  <div className="i">
                    <div className="c big light">
                        <img src="img/f1.jpg"/>
                    </div>
                </div>
                <div className="i dark">
                    <div className="c txt">
                        <h3>ENTRETIEN RÉCURRENT</h3>
                        <p>
                            Nous nous engageons à prendre soin de vos locaux de manière régulière, en tenant compte de vos contraintes et de vos attentes.
                        </p>
                        <p>    
                            Notre responsable de site veillera à ce que l'entretien de vos locaux soit notre priorité.
                        </p>
                    </div>
                </div>

                </div>

            
                <div className="block-content">

                <div className="i primary">
                    <div className="c txt">
                    <h3>NETTOYAGE DE CHANTIER</h3>
                <p>
                Du début à la fin, DSA est le partenaire incontournable pour le nettoyage de fin de chantier des acteurs du bâtiment, de la promotion immobilière à Lyon et dans sa région et pour les particuliers souhaitant faire une remise en état de leurs logements avant une vente ou après location.
                </p>
                <p>
                Nous intervenons AVANT, PENDANT et APRÈS les travaux pour garantir un environnement propre et sécurisé.
                </p>
                <p>
                Nos équipes spécialisées et formées se rendent sur vos sites 7j/7j afin de répondre à chacune de vos demandes.
                </p>
                    </div>
                </div>

                <div className="i">
                    <div className="c big noBorder">
                        <img src="img/n9.jpg"/>
                    </div>
                </div>

                </div>


                



                <div className="block-content revert">
                <div className="i">
                <div className="c big dark">
                        <img src="img/n6.jpg"/>
                    </div>
                </div>
                <div className="i light">
                    <div className="c txt">
                    <h3>PRESTATIONS SPÉCIFIQUES DE NETTOYAGE</h3>
                <p>
                 Chez DSA, nous nous engageons à répondre à toutes vos demandes spécifiques en matière de nettoyage.
                </p>
                <p>
                Notre équipe mettra en œuvre tout son savoir-faire pour répondre à vos attentes et garantir des résultats exceptionnels.
                </p>
                <p>
                Nettoyage des vitres (toute hauteur), remise en état, décapage mécanique des sols, gestion des encombrants, lavage mécanisé, nettoyage à l'autolaveuse, shampoing moquette, enlèvement de graffitis. 
                </p>
                <p>
                Nos équipes polyvalentes se rendent également disponible pour notre clientèle professionnelle et particulière pour entretenir au domicile ou sur le lieu de travail de notre client : véhicules, canapés, tapis.                </p>
                    </div>
                </div>

                </div>


            <div className="cta">

                <div className="i">
                    <div className="c txt">
                    <h3>Nous faisons la différence</h3>
                      <Link to="/rse" onClick={scrollTop}>
                      <span className="plusLink">
                            En savoir plus
                            <span><img src="/img/plus-dark.svg"/></span> 
                        </span>
                      </Link>
                    </div>
                </div>

                <div className="i noMobile">
                    <div className="c">
                        <img src="img/shapes/n2.svg"/>
                        <img src="img/shapes/triangle-ocre.svg"/>
                    </div>
                </div>
            </div>

        </>
  );
};

export default NosServices;