import Fade from 'react-reveal/Fade';

const Mentions = () => {
    return (
        <>
            <Fade top>
            <h2>Mentions Légales</h2>
			</Fade>
            <div className="p-content">

            <h4>Éditeur du Site :</h4>
            <p>
                <b>D.S.A</b><br/>
                12 Rue de La Part Dieu, 69003, Lyon<br/>
                <a href="mailto:contact@dsa-group.fr" title="contact@dsa-group.fr">contact@dsa-group.fr</a>
            </p>

            <h4>Directeur de la Publication :</h4>
            <p>Hugo DUPERRET</p>

            <h4>Création site web : <a href="https://metalyde.com" title="Metalyde">Metalyde</a></h4>

            <h4>Hébergeur du Site :</h4>
            <p>
            OVH<br/>
            2 rue Kellermann - 59100 Roubaix - France

            </p>

            

<h4>Propriété Intellectuelle :</h4>
<p>Tous les éléments du site (textes, images, logos, vidéos, etc.) sont la propriété exclusive de D.S.A ou de leurs propriétaires respectifs. Toute reproduction, distribution, ou utilisation sans autorisation préalable est strictement interdite.
</p>
<p>
Certains éléments visuels, tels que des images, présents sur ce site peuvent être issus du domaine public. Dans ce cas, ils sont utilisés conformément aux lois sur le domaine public et leur utilisation est autorisée sans restriction.
</p>

<h4>Données Personnelles :</h4>
<p>Nous collectons et utilisons vos données personnelles conformément à notre politique de confidentialité. Veuillez consulter notre politique de confidentialité pour en savoir plus sur la manière dont nous traitons vos données.</p>


<h4>Responsabilité :</h4>
<p>Nous nous efforçons de fournir des informations précises et à jour sur notre site Web. Cependant, nous ne pouvons pas garantir l'exactitude, l'exhaustivité ou la pertinence des informations fournies. Nous déclinons toute responsabilité quant aux éventuelles erreurs ou omissions sur le site.</p>

<h4>Loi Applicable et Juridiction :</h4>
<p>
Les présentes mentions légales sont régies par la loi applicable en France. Tout litige relatif à l'utilisation de notre site Web sera soumis à la juridiction des tribunaux compétents.

    </p>
<h4>Contact :</h4>
<p>
Si vous avez des questions concernant notre site Web ou nos mentions légales, veuillez nous contacter via les coordonnées fournies sur notre site.

</p>
            <p>Date de la dernière mise à jour : 25/7/2023</p>
            </div>
        </>

    )

}

export default Mentions;