import { Link} from "react-router-dom";

const Footer = () => {

  function scrollTop(event) {
    event.preventDefault(); // Prevent the default navigation behavior
    const destination = event.currentTarget.getAttribute("href"); // Get the destination URL from the 'to' attribute

    window.scroll({
        top: 0
      });
      
      // Add a slight delay before navigating to ensure smooth scrolling completes
      setTimeout(() => {
        window.location.href = destination; // Navigate to the target URL after scrolling
      }, 500); // Adjust the delay time as needed
  }

    return (
        <footer>
            <div>
            <Link to="/" onClick={scrollTop} className="footerLink">
			<svg width="744" height="742" viewBox="0 0 744 742" fill="none">
				<path fill="#fff" fillRule="evenodd" clipRule="evenodd" d="M740.268 512.366C730.229 512.057 720.427 511.756 707.149 480.497C707.149 480.497 703.201 469.085 595.719 211H588.261L512.366 411.148C503.014 433.776 495.178 451.315 488.522 464.919V424.208C488.522 424.208 450.339 403 374.418 403C336.201 403 312.347 412.966 298.55 422.039C302.086 407.71 304 391.549 304 373.338C304 242.653 206.079 215 117.505 215H0V222.582C19.5842 222.582 33.3821 248.898 33.3821 276.551V467.895C33.3821 498.224 19.1391 521.418 0 521.418V529H117.505C171.208 529 228.347 519.654 265.014 483.267C265.679 513.583 290.156 541.996 332.239 568.244L400.613 611.101C454.779 650.424 440.128 714.489 392.621 714.489C316.131 714.489 289.966 641.644 279.508 612.527C279.161 611.56 278.831 610.642 278.517 609.776H270.969V694.607C270.969 694.607 306.488 722 392.621 722C464.103 722 484.97 685.77 484.97 685.77C518.269 637.611 493.85 589.893 435.244 551.454L366.87 506.388C309.152 468.832 326.467 410.511 374.418 410.511C436.124 410.511 464.665 459.184 476.628 487.319C462.298 511.586 455.177 512.026 448.358 512.448C447.905 512.476 447.453 512.504 447 512.538V520H554.92V512.538C485.606 516.05 512.805 433.533 512.805 433.533H616.777L629.938 465.574C629.938 465.574 652.75 512.538 618.532 512.538V520H744V512.538C742.748 512.442 741.506 512.404 740.268 512.366ZM614.145 426.071H514.121L561.939 297.906L614.145 426.071ZM117.505 521.418C161.124 521.418 226.108 512.051 226.108 373.338C226.108 234.179 161.124 222.582 117.505 222.582H97.9209V521.418H117.505Z"/>
			</svg>	
			</Link>
                <p>
                  <a href="mailto:contact@dsa-group.fr" title="contact@dsa-group.fr">contact@dsa-group.fr</a><br/>
                  12 Rue de La Part Dieu, 69003, Lyon
                </p>
                <p>
                  © 2023 DSA
                  <Link to="/politique-de-confidentialite" onClick={scrollTop} className="mentions">
                         Politique de confidentialité
                    </Link>
                    <Link to="/mentions-legales" onClick={scrollTop} className="mentions">
                         Mentions Légales
                    </Link>
                </p>
            </div>
        </footer>
  );
};

export default Footer;