import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import './assets/_scss.scss';
import Lenis from '@studio-freight/lenis'
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Home from "./pages/Home.js";
import Contact from "./pages/Contact.js";
import NotFound from "./pages/NotFound.js";
import NousConnaitre from './pages/NousConnaitre';
import NosServices from './pages/NosServices';
import RSE from './pages/RSE';
import Politique from './pages/Politique';
import Mentions from './pages/Mentions';


function App() {
  const location = useLocation();
  const currentPath = location.pathname.replace('/', '');

  useEffect(() => {
    const pageDiv = document.getElementById('page');
    if (pageDiv && currentPath) { // Check if currentPath is not empty
      pageDiv.classList.add(currentPath);
    }

    return () => {
      if (pageDiv && currentPath) { // Check if currentPath is not empty
        pageDiv.classList.remove(currentPath);
      } 
    };
  }, [currentPath]);

  const lenis = new Lenis({
    lerp: 0.05,
    smoothWheel: true,
    orientation: 'vertical',
  });

  lenis.on('scroll', (e: Event) => {
  });

  function raf(time: number) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  return (
    <div className="App">
      <Header />
      <div id="page">

      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nous-connaitre" element={<NousConnaitre />} />
          <Route path="/nos-services" element={<NosServices />} />
          <Route path="/rse" element={<RSE />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/politique-de-confidentialite" element={<Politique />} />
          <Route path="/mentions-legales" element={<Mentions />} />

          <Route path="*" element={<NotFound />} />
      </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
