import { Link} from "react-router-dom";
import Fade from 'react-reveal/Fade';

const NousConnaitre = () => {
    function scrollTop(event) {
        event.preventDefault(); // Prevent the default navigation behavior
        const destination = event.currentTarget.getAttribute("href"); // Get the destination URL from the 'to' attribute
    
        window.scroll({
            top: 0
          });
          
          // Add a slight delay before navigating to ensure smooth scrolling completes
          setTimeout(() => {
            window.location.href = destination; // Navigate to the target URL after scrolling
          }, 500); // Adjust the delay time as needed
      }

    function scrollDown() {
        window.scroll({
          top: window.innerHeight,
          behavior: 'smooth'
        });
      }
    return (
        <>

        <div className="hero"  style={{ backgroundImage: "url('img/nous-connaitre-hero.jpg')" }}>
            <Fade top>
                <div className="title-container">
                     <h1>Nous Connaître</h1>
                </div>
            </Fade>
                <button className="plus" onClick={scrollDown}>
            En savoir plus
            <span className='center'><img src="/img/plus.svg"/></span>  
            </button>
        </div>
        <Fade top>
            <h2>UN ENGAGEMENT POUR VOTRE SATISFACTION</h2>
        </Fade>
            <Fade top>
          <div className="p-content">
            <p>
            Notre société D.S.A est soucieuse de placer son client au centre de ses préoccupations. <br/>
            Nos collaborateurs, fort d'une d'expérience de + de 15 ans dans les métiers de la propreté, adoptent un ensemble d'attitudes et de comportements visant à démontrer cette préoccupation client et à assurer une satisfaction sur la durée. 
            </p>
            <p>
            Nos valeurs s'articulent autour des points suivants : 
            </p>
          </div>
        </Fade>



          <div className="block-content">
                <div className="i primary">
                    <div className="c txt">
                    <h3>PASSION</h3>
                <p>
                Nos équipes sont engagées et dévouées.
                </p>
                <p>
                Nos agents sont tous salariés, qualifiés et formés au nettoyage professionnel avec un savoir-faire confirmé.
                </p>
              </div>
                </div>
                <div className="i">
                <div className="c big light">
                        <img src="img/dsa1.jpg"/>
                    </div>
                </div>
                </div>

            <div className="block-content revert">
                  <div className="i">
                    <div className="c big dark">
                        <img src="img/n4.jpg"/>
                    </div>
                </div>
                <div className="i ocre bold">
                    <div className="c txt">
                        <h3>RÉACTIVITÉ</h3>
                        <p>
                        Nos équipes sont disponibles 7j/7j pour répondre à vos questions, intervenir sur site avec du matériel à disposition dans nos différents camions pour assurer un remplacement ou une demande de dernière minute.
                        </p>
                        <p>    
                        De plus, nos équipes se rendent régulièrement sur votre site pour assurer l'approvisionnement des produits et du matériel.
                        </p>
                    </div>
                </div>

                </div>

            
                <div className="block-content">

                <div className="i light">
                    <div className="c txt">
                    <h3>IDENTIFICATION</h3>
                <p>
                Notre personnel 100 % salarié est identifié et identifiable sur site.
                </p>
                <p>
                De plus, un responsable qualité est affilié à votre site dès le début du contrat, afin d'échanger et de répondre à vos besoins.
                </p>
                <p>
                Il est également le garant de la qualité de votre site, c'est pourquoi un contrôle hebdomadaire est prévu sur votre site afin de faire le point sur les améliorations et modifications éventuelles. 
                </p>
                    </div>
                </div>

                <div className="i primary">
                    <div className="c big primary">
                        <img src="img/passe.jpg"/>
                    </div>
                </div>

                </div>


              
                <div className="block-content revert">
                <div className="i">
                <div className="c big">
                     <img src="img/n8.jpg"/>
                    </div>
                </div>
                <div className="i dark">
                    <div className="c txt">
                    <h3>EXCELLENCE</h3>
                <p>
                Nous nous engageons dans la voie de l'excellence et de la rapidité sans jamais sacrifier la qualité.
                </p>
                <p>
                Nous travaillons avec les meilleurs fournisseurs possibles afin d'assurer une prestation de qualité sur votre site et un bien être pour nos salariés. 
                </p>
                    </div>
                </div>

                </div>

                


                <div className="block-content">
                <div className="i primary">
                    <div className="c txt">
                    <h3>DIVERSITÉ</h3>
                <p>
                Nous assurons le nettoyage de tous types de locaux :  bureaux, restaurants, lieux festifs, lieux culturels, locaux commerciaux, banques, laboratoires, écoles, etc.
                </p>
                <p>
                Nous vous garantissons un travail de qualité, adaptable en fonction de vos besoins, de votre secteur d'activité, de vos horaires d'ouverture et de vos contraintes.
                </p>
                <p>
                En plus du nettoyage récurrent, notre société est également spécialisée dans le nettoyage de vos vitreries à toute hauteur, mais aussi dans les nettoyages mécanisés, le nettoyage à l'autolaveuse, le shampoing de vos moquettes et le nettoyage par injection extraction.
                </p>
                <p>
                Nos équipes assurent également, les mises en bene, le piquetage des déchets, l'enlèvement d'encombrants, les désinfections, le nettoyage de vos véhicules, de vos canapés et de vos tapis sur site (à domicile ou sur le lieu de travail).                </p>
              </div>
                </div>
                <div className="i">
                <div className="c big light">
                        <img src="img/f11.jpg"/>
                    </div>
                </div>
                </div>

            <div className="block-content revert">
                  <div className="i">
                    <div className="c big dark">
                      <img src="img/app.jpg"/>
                    </div>
                </div>
                <div className="i ocre bold">
                    <div className="c txt">
                        <h3>INNOVATION</h3>
                        <p>
                        Afin de faciliter les échanges entre votre société et la nôtre, D.S.A met en place une interface connectée sur laquelle vous retrouverez un espace client avec les choses suivantes :<br/>
                        Contrats, devis, attestation URSSAF, trombinoscope de nos salariés et du responsable qualité avec papiers d'identité et contrat de travail.
                        </p>
                        <p>    
                        De plus, nous mettons en place un chat avec notre service exploitation pour formuler vos remarques, vos demandes et vos retours.
                        </p>
                        <p>
                        Enfin des contrôles qualités effectués par nos équipes seront disponibles sur notre interface avec photos à l'appui afin de faire des comptes-rendus de nos prestations.
                        Parce que le sens du service et la satisfaction client sont les moteurs de notre société, D.S.A souhaite vous accompagner au quotidien dans l'amélioration et l'optimisation de votre image.                        </p>
                    </div>
                </div>

                </div>

{
            // <div className="block-content">
            //     <div className="i ocre bold">
            //         <div className="c txt">
            //         <h3>DIVERSITÉ</h3>
            //     <p>
            //     Un Responsable Qualité affilié à votre site.
            //     </p>
            //     <p>
            //     Une réactivité immédiate.
            //     </p>
            //     <p>
            //     Personnel 100% salarie & identifiable.
            //     </p>
            //     <p>
            //     Intervention exceptionnelle entre 24h et 72h.
            //     </p>
            //     <p>
            //     Des équipes disponibles 7j/7j.
            //     </p>
            //     </div>
            //     </div>
            //     <div className="i">
            //     <div className="c big light">
            //             <img src="img/dsa1.jpg"/>
            //         </div>
            //     </div>
            //     </div>
}


            <div className="cta">

            <div className="i noMobile">
                    <div className="c">
                        <img src="img/shapes/triangle-light.svg"/>
                        <img src="img/shapes/double-circle-2.svg"/>
                    </div>
                </div>

                <div className="i">
                    <div className="c txt">
                    <h3>Découvrez notre expertise</h3>
                      <Link to="/nos-services" onClick={scrollTop} >
                      <span className="plusLink">
                            En savoir plus
                            <span><img src="/img/plus-dark.svg"/></span> 
                        </span>
                      </Link>
                    </div>
                </div>

            </div>

        </>
  );
};

export default NousConnaitre;